import React from "react";

// Customizable Area Start
import { Paper } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import "../assets/css/AboutRaffles.css"
const baseURL = require("../../../framework/src/config.js").baseURL
// Customizable Area End


import AboutRafflesController, {
    Props
} from "./AboutRafflesController";
import i18nJs from "../../../components/src/TranslateLanguage";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

export default class AboutRaffles extends AboutRafflesController {
    constructor(props: Props) {
        super(props);
    }


    render() {

        return (
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <NavigationMenu
                    id={this.props.id}
                    navigation={this.props.navigation}
                    title="About Us"
                >
                    <Paper style={{ width: "100%", overflow: "hidden" }}>
                        <div className="content-wrapper about-content-raffles">
                            <div className="raffles-about-block">
                                <h6>{i18nJs.t('About Us')}</h6> 
                                {
                                    this.state.aboutData.map((item: any, index: number) => {
                                        return (item.attributes && <div key={index} className="raffles-about-content">
                                            <div className="raffles-about-content-image">
                                                <img src={baseURL + item.attributes?.image?.url} alt="About Us" />
                                            </div>
                                            <div className="raffles-about-inner-content" dangerouslySetInnerHTML={{ __html: this.state.selectedLanguage == "ar" ? item.attributes?.description_ar : item.attributes?.description }} />
                                        </div>)
                                    })
                                }
                            </div>
                        </div>
                    </Paper>
                </NavigationMenu>
            </ThemeProvider>
        );
    }
}
