import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  getStorageData,
  setStorageData
} from "../../../framework/src/Utilities";
import React from "react";
//@ts-ignore
import i18n from 'i18n-js'

interface CourseData {
  data: Course[];
}

interface Course {
  id: string;
  type: string;
  attributes: {
      course_name: string;
      title: string;
      information: string;
      description: string;
      profile_id: number;
      category_id: number;
      language: string;
      level: string;
      price: number;
      course_type: string;
      no_of_lessons: number;
      total_duration_of_lessons: number;
      lessons: {
          data: Lesson[];
      };
      course_image: {
          url: string;
      };
      purchased: boolean | null; // adjust type accordingly
      average_rating: number;
      lecturer: string;
  };
  
}
interface Lesson {
  id: string;
  type: string;
  attributes: {
      title: string | null;
      description: string;
      duration: string | null;
      lesson_video: {
          url: string;
      };
      lesson_attachment: {
          url: string;
      } | null;
      video_subtitle: string | null;
      lesson_image: {
          url: string;
      };
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  menu_collape: boolean;
  userToken: string | null;
  allPopularCourses: any;
  setTutorialDialogOpen: boolean;
  isPlaying: boolean;
  videoRef: React.RefObject<HTMLVideoElement>;
  isHover: boolean,
  tutorialVideoSrc: any;
  locale: any
  landingPCat: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMostPopularCoursesId:string = "";
  getTutorialDataCallLandingId:string = "";
  getCategoriesApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      menu_collape: false,
      userToken: '',
      allPopularCourses: [],
      setTutorialDialogOpen: false,
      isPlaying: false,
      videoRef: React.createRef(),
      isHover: false,
      tutorialVideoSrc: [],
      locale:'',
      landingPCat: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const Idss = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.responseMostPopularCourse(Idss,response);

      if(this.getTutorialDataCallLandingId == Idss) {
        if(response && response.data){
          this.setState({ tutorialVideoSrc : response.data[0].attributes.videos[0].url});
        }
      }
      if(this.getCategoriesApiCallId === Idss && response){
        this.setState({landingPCat : response.data})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getMostPopularCourses();
    this.getTutorialDataLanding()
    let token = await getStorageData("token");
    this.setState({userToken: token});
    (!token || token == null) && setStorageData("role", "guest");
    this.getCategories();
  }

  responseMostPopularCourse = (apiRequestCallId:string, responseJson:CourseData) => {
    (apiRequestCallId === this.getMostPopularCoursesId && responseJson.data) && this.getAllMostPopularCourses(responseJson)
  }

  getAllMostPopularCourses = (responseJson: any) => {
    if(responseJson.data) {
      this.setState({allPopularCourses:responseJson.data})
    }
  }
  
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  onClickRedirect = (name:string) => {
    this.onClickRedirectName(name);
  };
  onClickMenuCollapeOn = () => {
    this.setState({ menu_collape: true });
  };

  onClickMenuCollapeOff = () => {
    this.setState({ menu_collape: false });
  };
  onClickRedirectName = (name: string) => {
    this.props.navigation.navigate(name);
  };

  getMostPopularCourses = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMostPopularCoursesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMostPopularCoursesApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  stringAvatar = (name: string) => {
    const nameParts = name.split(' ');
    return {
      children: nameParts.length > 0 ? `${nameParts[0][0]}` : '', 
    };
  }

  convertDurationHours = (minutesOfCourse: number) => {
    const hourConvertCourse = Math.floor(minutesOfCourse / 60);
    const remainingMinutesConvertCourse = minutesOfCourse % 60 === 0 ? '' : `and ${minutesOfCourse % 60} mins`;
    return `${hourConvertCourse} hr ${remainingMinutesConvertCourse}`;
  }

  handleClickOpen = () => {
    this.setState( { setTutorialDialogOpen : true});
  }

  handleCloseTutorial = () => {
    this.setState( { setTutorialDialogOpen : false});
    };

  handleVideoTutorialPlay = () => {
      this.setState({ isPlaying: true });
  };

  handleVideoTutorialPause = () => {
    this.setState({ isPlaying: false });
  };

  togglePlayPauseTutorial = () => {
    this.setState({ isPlaying: !this.state.isPlaying });
    if (this.state.videoRef.current) {
      this.state.isPlaying
        ? this.state.videoRef.current.pause()
        : this.state.videoRef.current.play();
    }
  };

  handleSkipForwardTutorial = (seconds: any) => {
    this.state.videoRef.current && (this.state.videoRef.current.currentTime += seconds)
  };

  handleSkipBackwardTutorial = (seconds: any) => {
    this.state.videoRef.current && (this.state.videoRef.current.currentTime -= seconds)
  };

  onMouseEnterTutorial = () => {
    this.setState({ isHover : true});
  }
  onMouseLeaveTutorial = () => {
    this.setState({ isHover : false});
  }

  getTutorialDataLanding = async() => {

    const getProfileIdRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTutorialDataCallLandingId = getProfileIdRequest.messageId;
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTutorialDataLanding
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getProfileIdRequest.id, getProfileIdRequest);
  }
  switchLanguage = (lang:any) => {
    i18n.locale = lang;
    localStorage.setItem('language',lang)
    this.setState({ locale: lang });
    
  };
  truncateText = (text:any, limit : any) => {
    if (text && text.length > limit) {
      return `${text.slice(0, limit)}...`;
    }
    return text;
  };
  getCategories = () => {
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleLearnMore = (id:any) => {
    this.props.navigation.navigate('GuestCourse', { courseId: id });
  };

  // Customizable Area End
}
