import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import ContactusController, { Props } from "./ContactusController";
import GuestNavigationMenu from "../../dashboard/src/GuestNavigationMenu.web";
import "../assets/css/StudentContactus.css";
import Maps from "../../maps/src/Maps.web";
import { question_mark, google_maps } from "./assets";
import i18nJs from "../../../components/src/TranslateLanguage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

interface Answer {
  id: number;
  question_id: number;
  name: string;
  name_ar: string;
  created_at: string;
  updated_at: string;
}

interface Question {
  id: string;
  type: string;
  attributes: {
    name: string;
    name_ar: string;
    answers: Answer[];
  };
}
// Customizable Area End

export default class GuestContactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>          
        <GuestNavigationMenu id={this.props.id} navigation={this.props.navigation} title={i18nJs.t('Contact Us')}>
          <Paper 
          style={{ 
            width: "100%", 
            overflow: "hidden" 
            }}>
            <Box className="content-wrapper">
              <Box className="dashboard-heading-data">
                  <Grid container xs={12} 
                  style={{marginBottom:"10px"}} 
                  justifyContent="space-between">
                      <Grid item>
                        <Box data-test-id="guest-dashboard" className="dashboard-info" style={{gap:'10px'}}>
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="25" height="24" 
                            viewBox="0 0 24 24" fill="none">
                            <mask id="mask0_528_14747" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_528_14747)">
                              <path d="M4 17C3.45 17 2.97917 16.8042 2.5875 16.4125C2.19583 16.0208 2 15.55 2 15V7.15C2 6.9 2.07083 6.65417 2.2125 6.4125C2.35417 6.17083 2.55 5.98333 2.8 5.85L10.5 2L18.05 5.85C18.25 5.95 18.4208 6.10833 18.5625 6.325C18.7042 6.54167 18.8 6.76667 18.85 7H15.925L10.5 4.25L4 7.475V17ZM7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V10C5 9.45 5.19583 8.97917 5.5875 8.5875C5.97917 8.19583 6.45 8 7 8H20C20.55 8 21.0208 8.19583 21.4125 8.5875C21.8042 8.97917 22 9.45 22 10V19C22 19.55 21.8042 20.0208 21.4125 20.4125C21.0208 20.8042 20.55 21 20 21H7ZM13.5 15.35L7 12V19H20V12L13.5 15.35ZM13.5 13.35L20 10H7L13.5 13.35Z" fill="#1C1B1F" />
                            </g>
                          </svg>
                          <Box className="user-id guest-mail0on-us">
                          <h3 data-test-id="guest-mail-on-us">{i18nJs.t('Mail us on')}</h3>
                            <span>{this.state.getEmailNumberData.email}</span>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box className="dashboard-info" style={{gap:'10px'}}>
                          <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M512.46 193.573c-90.154 0-163.493 72.018-163.493 160.512 0 88.476 73.339 160.416 163.493 160.416 90.149 0 163.449-71.94 163.449-160.416C675.909 265.586 602.609 193.573 512.46 193.573M512.46 443.429c-50.116 0-90.888-40.09-90.888-89.378 0-49.276 40.771-89.384 90.888-89.384 50.082 0 90.849 40.107 90.849 89.384C603.309 403.337 562.542 443.429 512.46 443.429M895.11 377.906c0-207.235-171.655-375.823-382.65-375.823-210.958 0-382.542 168.588-382.542 375.823 0 162.531 235.576 490.029 336.917 623.266 10.667 14.059 27.74 22.416 45.627 22.416 17.846 0 34.955-8.354 45.662-22.416C659.427 867.936 895.11 540.48 895.11 377.906M518.492 944.288l-6.032 8.021-6.037-8.021C315.12 690.093 196.191 473.05 196.191 377.906c0-171.303 141.85-310.673 316.269-310.673 174.378 0 316.234 139.37 316.234 310.673C828.729 473.086 709.873 690.093 518.492 944.288" /></svg>
                          <Box className="user-id" >
                          <h3>{i18nJs.t('Address')}</h3>
                            <span>{this.state.getEmailNumberData.address}</span>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item>
                      <Button
                      style={{
                        borderRadius: 8,
                        background: "#EAECED",
                        color: "#000000",
                        padding: "6px, 10px, 6px, 10px",
                        boxShadow: "none",
                        textTransform: "none",
                        lineHeight: "20px",
                        fontSize: "16px",
                        fontWeight: 700,
                      }}
                    variant="contained"
                    data-test-id="dialog-guest"
                    onClick={this.handleDialogMapOpen}
                  >
                    <img src={google_maps} alt="" />
                    <span style={{ marginLeft: "10px", marginRight:'10px' }}>{i18nJs.t('Get Directions')}</span>
                  </Button>
                      </Grid>
                </Grid>
              </Box>

              <Box className="dashboard-conetnt">
                <Box className="block-heading">
                  <h3  style={{gap:'10px'}}>
                  <img src={question_mark}alt="" />
                  {i18nJs.t('Frequently asked questions')}</h3>
                </Box>
                <Box className="block-content">
                  <Box className="faq-wrapper">
                    {
                      this.state.getContactData.map((item: Question, index: number) => {
                        return (
                          <Box key={index} className={`${this.state.goals.includes(item?.attributes.name) ? "faq-block active" : "faq-block"}`}>
                           <Box className={`faq-heading ${i18nJs.locale === 'ar'? 'rtl-faq-heading': ''}`}  data-test-id={`heading${index}`}>
                              <h6>{this.state.selectedLanguage == "ar" ? 
                              item.attributes.name_ar : item.attributes.name}</h6>
                              <span 
                              data-test-id={`clickchange${index}`} 
                              onClick={() => this.goalsHandle(item?.attributes.name)}>
                              <svg 
                              className="show" xmlns="http://www.w3.org/2000/svg" 
                              height="1em"
                              viewBox="0 0 448 512">
                                <path
                                  d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                              </svg>
                                <svg 
                                className="hide" xmlns="http://www.w3.org/2000/svg" 
                                height="1em"
                                  viewBox="0 0 448 512">
                                  <path
                                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                </svg>
                                </span>
                            </Box>
                            <Box className="faq-content">
                              <p>{this.state.selectedLanguage == "ar" ? item.attributes.answers[0].name_ar : item.attributes.answers[0].name}</p>
                            </Box>
                          </Box>)
                      })
                    }
                  </Box>
                </Box>
              </Box>
              <Dialog onClose={this.handleDialogMapClose} open={this.state.openMapDialog} fullWidth maxWidth="lg" data-test-id="dialogclose">
              <DialogTitle style={{ height: '70px' }}>
                  {this.state.city ? this.state.city:'Place name'}
                  <Box sx={{fontSize:"small"}}>{this.state.street ? this.state.street:''}</Box>
                  <Box className="close-btn" 
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    top: '15px'
                  }}>
                    <span 
                    data-test-id="landing-page-go-back" 
                    onClick={this.handleDialogMapClose}>
                      <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      height="1em" 
                      viewBox="0 0 384 512">
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    </span>
                  </Box>
                </DialogTitle>

                <DialogContent>
                  <Maps addressProp={this.state.getEmailNumberData.address} navigation={undefined} id={""} />
                </DialogContent>
              </Dialog>
            </Box>
          </Paper>
        </GuestNavigationMenu>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
