import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
//added for video rendering
import React from "react";
// Customizable Area Start
import { getStorageData, setStorageData,removeStorageData } from "../../../framework/src/Utilities";
import toast  from "react-hot-toast";
import i18nJs from "../../../components/src/TranslateLanguage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isloading: boolean;
  token: string;
  sessionList:any;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  categoriesData: any;
  categoriesFashion: any;
  selectedCategory: any;
  changeProduct: any;
  filteredata: any;
  filtercarddata: any;
  noCourseSelected: string;
  videoRef: React.RefObject<HTMLVideoElement>;
  divRef: React.MutableRefObject<HTMLDivElement | null>;
  isPlaying: boolean;
  videolink: string;
  searchHistory:boolean;
  searchHistoryData:any;
  searchValue:string;
  searchData:any;
  searchDataAll:any;
  byType:string;
  byLevel:string;
  sliderValue:any;
  selectLanguage:string;
  expanded:boolean;
  selectedCategorySearch:string
  open:boolean;
  rating:number;
  writereview:string;
  studentSurvey:any;
  studentSurveyData:any;
  getQuestionloding:boolean
  anchorEl:any;
  sortBy:any;
  singleSessionData:any
  userRole:string;
  assessmentBtnStatus: boolean;
  courseId: number;
  assessMent: string;
  openMap:boolean;
  isVisibleModal: boolean;
  responseFromTabbyMessage: string;
  city:any,
  street:any,
  lessonVideoId:number,
  isReviewDone:boolean,
  learnMoreCourseId:any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  // Customizable Area Start
  getAllCategoriesApiCallId: any;
  getCategoriesSessionApiCallId: any;
  submitReviewApiCallId: any;
  getCategoriesSearchApiCallId: any;
  getstudentSurveysAPICallId:any;
  studentsurveysId:any;
  getparticularCategoriesId:any
  getPurchasedInfoId: any;
  getAssessmentAPICallId: string = "";
  getReviewRatingCallId:any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      searchHistory:false,
      searchHistoryData:[],
      isloading:false,
      token: "",
      sessionList:[],
      categoriesArray: [],
      category: "",
      videoRef: React.createRef(),
      divRef: React.createRef(),
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      categoriesData: [],
      categoriesFashion: [],
      selectedCategory: "",
      changeProduct: [],
      filteredata: [],
      filtercarddata: {},
      noCourseSelected: "false",
      isPlaying: false,
      videolink:"",
      searchValue:"",
      searchData:[],
      searchDataAll:[],
      byType:"",
      byLevel:"",
      sliderValue:[0, 20000],
      selectLanguage:"",
      expanded:false,
      selectedCategorySearch: "",
      open:false,
      rating:0,
      writereview:"",
      studentSurvey:[],
      studentSurveyData:{},
      getQuestionloding:false,
      anchorEl:null,
      sortBy:[
        {
          name:"Oldest to Newest",
          checked:false
        },
        {
          name:"Newest to Oldest",
          checked:false
        }
      ],
      singleSessionData:{},
      userRole:"",
      assessmentBtnStatus: false,
      courseId: 0,
      assessMent: "",
      openMap: false,
      isVisibleModal: false,
      responseFromTabbyMessage: "success",
      city:'',
      street:'',
      lessonVideoId:0,
      isReviewDone: false,
      learnMoreCourseId:null,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
   this.onCallHistory()
 
   
    let learnMoreId = this.props.navigation?.history?.location?.state?.courseId;
    if(learnMoreId){
      {this.setState({learnMoreCourseId : learnMoreId})}
    }
    let SearchValue = await getStorageData("searchValue")
    if(SearchValue){
      this.setState({searchValue:SearchValue},() => {
        removeStorageData('searchValue')
      })
    }
    document.addEventListener("mousedown", this.handleOutsideClick);
    let alltoken = await getStorageData("token") || ""
    let languageKey = await getStorageData("language");
    let newLanguage = languageKey === "en" ? "english" : "arabic";
    
    this.setState({ token: alltoken }, () => {
      this.getAllCategories(newLanguage)
      this.getAllCategoriesSearch(this.state.sliderValue,this.state.byType,this.state.byLevel,this.state.selectLanguage,this.state.selectedCategorySearch)
      this.getStudentSurveys()
      this.getAssessIdCourse();
      const ParamIDSearch = this.props.navigation?.getParam("id");
      setStorageData('couseId', ParamIDSearch);
      ParamIDSearch && this.getparticularCategories(ParamIDSearch)
      ParamIDSearch && this.getReviewRating()
    });
    let getUserRole = await getStorageData('role')
    this.setState({userRole: getUserRole})
    this.responseFromTabbyMethod();
    
    // Customizable Area End
  }
  
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const ParamIDSearch = this.props.navigation?.getParam("id");

      (apiRequestCallId === this.getAllCategoriesApiCallId && responseJson && responseJson.data) && this.setresponseFun(responseJson.data)
            
      this.conditionForProfile(apiRequestCallId, ParamIDSearch, responseJson)
      if (apiRequestCallId === this.getCategoriesSearchApiCallId && responseJson) {
        responseJson.data && this.setState({searchDataAll:responseJson.data}) 
        !responseJson.data && this.setState({searchDataAll:[]}) 
      }
      if (apiRequestCallId === this.getAssessmentAPICallId && responseJson) {
          this.getAssessmentFunc(responseJson)
      }
      if (apiRequestCallId === this.getReviewRatingCallId && responseJson) {
        this.getReviewRatingResponse(responseJson)
      }
      (apiRequestCallId === this.getCategoriesSessionApiCallId && responseJson) && this.getSessionResponce(responseJson)
      this.shortCodeFunction(apiRequestCallId,responseJson)
      this.shortCodeFunctionSecond(apiRequestCallId,responseJson)
      this.shortCodeFunctionThird(apiRequestCallId,responseJson)
      this.shortCodeFunctionFourth(apiRequestCallId,responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  conditionForProfile = (apiRequestCallId: any, ParamIDSearch: any, responseJson: any)  => {
    if (apiRequestCallId === this.getCategoriesApiCallId && responseJson && !ParamIDSearch) {
      this.setState({ categoriesData: responseJson.data,selectedCategory: responseJson.data[0]?.attributes?.id, isloading:false}, () => {
        this.handlefilter(responseJson.data[0]?.attributes?.id)
      });
    }
    if (apiRequestCallId === this.getCategoriesApiCallId && responseJson) {
      this.setState({ categoriesFashion: responseJson.data})
    }
  }

  addSession = (data:any,idLessonVideo:number) => {
    const ParamID = this.props.navigation?.getParam("id");
    this.setState({singleSessionData:data}, () => {
      this.setWatchSession(data.id)
      this.getparticularCategories(ParamID);
    })
    this.setWatchSession(data?.id)
    this.getparticularCategories(ParamID);
    this.setState({
      lessonVideoId:idLessonVideo
    })
  }

  renderImageName = (URL:string) => {
    const aray = URL && URL.split("/");
    return aray && aray[aray?.length - 1]
  }

  handleDownload = (fileUrl:string) => {
    fetch(fileUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob
        const blobUrl = window.URL.createObjectURL(blob);

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = this.renderImageName(fileUrl); // Specify the desired filename here

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(error => {
        console.error('There was a problem with your fetch operation:', error);
      })

  };

  getAssessmentFunc = ( responseJson: { data: { id: string}}) => {
     this.setState({
      assessMent: responseJson?.data?.id
    })
  }

  getSessionResponce = (responseJson:any) => {
    if(responseJson.data){
      this.setState({sessionList:responseJson.data ? responseJson.data : [], singleSessionData:responseJson.data[0]})
    } else {
      this.setState({sessionList: []});
    }
  }

  shortCodeFunctionSecond  = (apiRequestCallId:any, responseJson:any) => {
    apiRequestCallId === this.getstudentSurveysAPICallId && responseJson && this.handleStudentSurveys(responseJson)
  }

  shortCodeFunctionThird  = (apiRequestCallId:any,responseJson:any) => {
    // apiRequestCallId === this.studentsurveysId && responseJson && this.handleFunctionClose()
  }

  shortCodeFunction = (apiRequestCallId:any,responseJson:any) => {
    apiRequestCallId === this.submitReviewApiCallId && responseJson && this.submitReviewRespose(responseJson)
  }

  submitReviewRespose = (responseJson:any) => {
    responseJson.data && this.handleCallNow()
  }

  shortCodeFunctionFourth  = (apiRequestCallId:any,responseJson:any) => {
    if (apiRequestCallId === this.getparticularCategoriesId && responseJson.data) {
      this.setState({noCourseSelected: "false"})
      this.setState({ filtercarddata : responseJson.data } , () => {
        this.assesmentStatusFunc();
    })

    } else if (apiRequestCallId === this.getparticularCategoriesId && !responseJson.data) {
      this.setState({noCourseSelected: "true"})
    }

    if (apiRequestCallId === this.getPurchasedInfoId && responseJson.data) {
      if (responseJson.data.attributes.purchased) {
        this.props.navigation.navigate("FashionDesign",{id:responseJson.data.id})
      } else {
        this.onClickRedirct1(responseJson.data.id)
      }
    }
  }

  assesmentStatusFunc = () => {
      if(this.state.filtercarddata.attributes?.lessons?.data.length === this.state.filtercarddata.attributes?.no_of_lessons_completed  ) {
         this.setState({
            assessmentBtnStatus: true
         })
      }
  }
  

  async componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = (e:any) => {
    if (this.state.divRef.current && !this.state.divRef.current.contains(e.target)) {
      this.onClickSearchModalClose()
    }
  };
  setresponseFun = (responseJson:any) => {
    const ParamID = this.props.navigation?.getParam("id");
    ParamID && this.setState({changeProduct:responseJson},() => {this.handlefilterSecond(ParamID)}) 
    !ParamID && this.setState({changeProduct:responseJson},() => {this.getCategories()})
  }
  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };

  setFirstSession = () => {
    this.setState({singleSessionData:this.state.sessionList[this.state.lessonVideoId]},() => {
      if (this.state.videoRef.current) {
          this.state.videoRef.current.requestFullscreen();
        }
    })
    
}

  handlefilter = (category: string) => {
    let data = this.state.changeProduct?.filter((val: any) => val?.attributes?.category_id === parseFloat(this.state.learnMoreCourseId ? this.state.learnMoreCourseId :category));
    let filterData = data.find((val: any) => val.id === data[0]?.id);
    this.setState({noCourseSelected: data[0]?.id ? "false" : "true",learnMoreCourseId: null});
    this.setState({ filteredata: data });
    this.getparticularCategories(data[0]?.id)
    this.getAllCategoriesSession(filterData?.id)
  };


  handlefilterSecond = (category: string) => {
    this.getparticularCategories(category)
    this.getAllCategoriesSession(category)
  };

  handlecarditemfilter = (category: string) => {
    this.getparticularCategories(category)
    this.getAllCategoriesSession(category)
  };

  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      let data = {
        categories: [{ name: this.state.category }]
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let data = {
        sub_category: {
          name: this.state.subCategory
        },
        parent_categories: this.state.selectedCategoryID
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addSubCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteSubCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategories = () => {
    
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  togglePlayPause = () => {
    this.setState({ isPlaying: !this.state.isPlaying });
    if (this.state.videoRef.current) {
      this.state.isPlaying
        ? this.state.videoRef.current.pause()
        : this.state.videoRef.current.play();
    }
  };

  handleVideoPlay = () => {
    this.setState({ isPlaying: true });
  };

  handleVideoPause = () => {
    this.setState({ isPlaying: false });
  };

  handleSkipForward = (seconds: any) => {
    if (this.state.videoRef.current) {
      this.state.videoRef.current.currentTime += seconds;
    }
  };

  handleSkipBackward = (seconds: any) => {
    if (this.state.videoRef.current) {
      this.state.videoRef.current.currentTime -= seconds;
    }
  };

  getAllCategories = (lang:any) => {
    this.setState({isloading:true})
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.AllCategoryAPIEndPoint}=${lang}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getparticularCategories = (identity:string|number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getparticularCategoriesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.userRole !== "guest" ?
      configJSON.AllParticularCategoryAPIEndPoint + identity
      : configJSON.AllParticularCategoryAPIEndPointGuest + identity
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllCategoriesSession = (session:string) => { 
    // token required for lesson api
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesSessionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AllCategorySessionAPIEndPoint + parseFloat(session)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onClickRedirct = (id:string) => {
    this.props.navigation.navigate("FashionDesign",{id:id})
  }

  onClickRedirectToCourseDetails = (id: string) => {
    if(this.state.userRole === "guest") {
      this.props.navigation.navigate("Home")
    } else {
      this.getCoursePurchasedInfo(id);
    }
  }

  onClickRedirct1 = (id:string) => {
    this.props.navigation.navigate("Payments",{id:id})
  }

  onClickSearchModalOpen = () => {
    this.setState({searchHistory:true})
    this.onSearchLogic()
  }

  onClickSearchModalClose = () => {
    this.setState({searchHistory:false})
    let prev = localStorage.getItem('p')
    let now = localStorage.getItem('user_id')
    if(now !== prev){
      let now = localStorage.getItem('user_id')
      if(now){
        localStorage.setItem('p',now)
      }
      localStorage.removeItem('test')
      localStorage.removeItem('searchHistory')
    }
    this.onSearchLogic()
  }

  onSearchLogic = () => {
    if (this.state.searchValue.length >= 3 && !this.state.searchHistoryData.includes(this.state.searchValue)) {
      let searchData = [ this.state.searchValue,...this.state.searchHistoryData]
      this.setState({searchHistoryData: searchData})
      setStorageData("searchHistory", JSON.stringify(searchData))
      return true;
    }
  }

  onsearchValue = (event:any) => {
    this.setState({searchValue:event.target.value})
    if(window.location.pathname !== "/Searching"){
      setStorageData("searchValue",event.target.value)
      this.props.navigation.navigate('Searching')
    }
  }

  onClickSearchValue = (value:string) => {
    this.setState({searchValue:value,searchHistory:false})
    if(window.location.pathname !== "/Searching"){
      setStorageData("searchValue",value)
      this.props.navigation.navigate('Searching')
    }
  }

  onChangeCheckBoxOne = (event:any) => {
    let byTypeValue = event.target.checked ? event.target.name : ""
    this.setState({byType:byTypeValue})
    this.getAllCategoriesSearch(this.state.sliderValue,byTypeValue,this.state.byLevel,this.state.selectLanguage,this.state.selectedCategorySearch)
  }

  onChangeCheckBoxTwo = (event:any) => {
    let byLevelValue = event.target.checked ? event.target.name : ""
    this.setState({byLevel:byLevelValue})
    this.getAllCategoriesSearch(this.state.sliderValue,this.state.byType,byLevelValue,this.state.selectLanguage,this.state.selectedCategorySearch)
  }

  getAllCategoriesSearch = (slider:any,byType:string,byLevel:string,language:string,category:string) => {
    let endpoint;
    let level = byLevel
    let bylanguage = language
    let bycategory = category
    let bytype = byType
    if(this.state.expanded){
      endpoint = `?level=${level}&language=${bylanguage}&first_price=${slider[0]}&last_price=${slider[1]}&category_id=${bycategory}&type=${bytype}`
    }else{
      endpoint = `?level=&language=&first_price=&last_price=&category_id=&type=`
    }
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesSearchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.AllCategorySearchAPIEndPointGuest + endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSearchfilter = (category: string) => {
    this.setState({selectLanguage:category})
    this.getAllCategoriesSearch(this.state.sliderValue,this.state.byType,this.state.byLevel,category,this.state.selectedCategorySearch)

  };

  handleSearchcategoryfilter = (category: string) => {
    this.setState({selectedCategorySearch:category})
    this.getAllCategoriesSearch(this.state.sliderValue,this.state.byType,this.state.byLevel,this.state.selectLanguage,category)
  };

  onChangeSlider = (event:any, newValue:any) => {
    this.setState({sliderValue:newValue})
    this.getAllCategoriesSearch(newValue,this.state.byType,this.state.byLevel,this.state.selectLanguage,this.state.selectedCategorySearch)
  }  

  onClickOpenExpand = () => {
    this.setState({expanded:!this.state.expanded},() => {
      this.getAllCategoriesSearch(this.state.sliderValue,this.state.byType,this.state.byLevel,this.state.selectLanguage,this.state.selectedCategorySearch)
    })
  }

  onCallHistory = async() => {
    let prev = localStorage.getItem('p')
    let now = localStorage.getItem('user_id')
    if(prev == now){
    let SearchHistory = await getStorageData("searchHistory")
    if(SearchHistory){
      this.setState({searchHistoryData:JSON.parse(SearchHistory)})
    }}
  }

  clearAllHistory = () => {
    removeStorageData('searchHistory')
    this.setState({searchHistoryData:[],searchValue:""})
    this.onCallHistory()
  }

  selectHistoryDelete = (index:number) => {
    let deleteData = this.state.searchHistoryData.filter((object:any, ind:number) => ind !== index)
    this.setState({searchHistoryData:deleteData})
    setStorageData("searchHistory",JSON.stringify(deleteData))
  }
  
  handleOpen = () => {
    const ParamID = this.props.navigation?.getParam("id");
    const httpData = {
      description: this.state.writereview,
      rating : this.state.rating,
      reviewable_id: parseFloat(ParamID),
      reviewable_type:"BxBlockProfile::Course"
    }
    if(this.state.studentSurvey?.length){
      this.setState({open:true})
    } else {
      this.submitReview(httpData);
    }
  }

  handleCallNow = () => {
    toast.success(i18nJs.t('Review submitted successfully'))
    this.handleCloseNow()
  }

  handleClose = () => {
    const ParamID = this.props.navigation?.getParam("id");
    const httpData = {
      description: this.state.writereview,
      rating : this.state.rating,
      reviewable_id: parseFloat(ParamID),
      reviewable_type:"BxBlockProfile::Course"
    }
    this.setState({open:false,isReviewDone:true})
    this.submitReview(httpData)
    this.getStudentSurveys()
  }

  handleCloseNow = () => {
    this.setState({open:false,isReviewDone:true})
    this.getStudentSurveys()
  }
  

  onchangeRating = (newValue:any) => {
    newValue !== null && this.setState({rating:newValue})
  }

  onChangeWriteReview = (event:any) => {
    this.setState({ writereview: event.target.value })
  }

  onClickSubmitData = () => {
     this.state.rating !== 0 && this.handleOpen()
     this.state.rating === 0 && toast.error(i18nJs.t('Please fill the review'))
  }

  submitReview = (httpData:any) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitReviewApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitReviewAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getStudentSurveys = () => {
    this.setState({getQuestionloding:true})
    const getParam = this.props.navigation.getParam("id");
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getstudentSurveysAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.studentSurveysAPIEndPoin}?course_id=${getParam}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAssessIdCourse = () => {
    const getParam = this.props.navigation.getParam("id");
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssessmentAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAssesessmentByCourse + getParam,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleStudentSurveys=(responseJson:any)=>{
    this.setState({getQuestionloding:false})
    let lstTmp = responseJson?.data?.attributes?.survey_questions?.map((obj:any) => {
      return({
        ...obj,
        answer:""
      })
    })
    this.setState({studentSurvey:lstTmp,studentSurveyData:responseJson.data})
  }

  onChangeSurvay = (event:any, index:number) => {
    let lstTmp = [...this.state.studentSurvey];
    lstTmp[index] = { ...lstTmp[index], ["answer"]: event.target.value };
    this.setState({studentSurvey:lstTmp})
  }

  submitAnswerData = () => {
    let answerData = this.state.studentSurvey.reduce((acc:any, surveyItem:any) => {
      acc[surveyItem.id] = surveyItem.answer;
      return acc;
    },{})

    return answerData
  }

  submitData = () => {
    const ParamID = this.props.navigation?.getParam("id");
    const httpData = {
      description: this.state.writereview,
      rating : this.state.rating,
      reviewable_id: parseFloat(ParamID),
      reviewable_type:"BxBlockProfile::Course"
    }
    this.submitAnswerDataCall()
    this.submitReview(httpData)
  }

  submitAnswerDataCall = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const httpBody = {
      "data": {
        "survey_id":parseInt(this.state.studentSurveyData.id),
        "question_answer": this.submitAnswerData()
      }
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.studentsurveysId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentSurveysPostAPIEndPoin
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleFillterClick = (event:any) => {
    this.setState({anchorEl:event.currentTarget})
  };

  handleFillterClose = () => {
    this.setState({anchorEl:null})
  };

  onchangeSort = (event:any, index:number) => {
    let lstTmp = this.state.sortBy.map((obj:any,indexs:number) => {
      if(indexs === index){
        return({
          ...obj,
          checked:event.target.checked
        })
      }else{
        return({
          ...obj,
          checked:false
        })
      }
    })
    let dataNumber =  this.state.sortBy[index]
    this.setState({sortBy:lstTmp})
    if(event.target.checked === true){
      switch (dataNumber.name) {
        case "Oldest to Newest":
          let arrayOfObjects = this.state.filteredata.sort((a:any, b:any) => a.id - b.id);
          let filterData = arrayOfObjects.find((val: any) => val.id === arrayOfObjects[0]?.id);
          this.setState({filteredata:arrayOfObjects})
          this.getparticularCategories(arrayOfObjects[0]?.id)
          this.getAllCategoriesSession(filterData?.id)
          break;
        case "Newest to Oldest":
          let arrayOfObjects2 = this.state.filteredata.sort((a:any, b:any) => b.id - a.id);
          let filterData2 = arrayOfObjects2.find((val: any) => val.id === arrayOfObjects2[0]?.id);
          this.setState({filteredata:arrayOfObjects2})
          this.getparticularCategories(arrayOfObjects2[0]?.id)
          this.getAllCategoriesSession(filterData2?.id)
          break;
        default:
          break;
    }
    }else{
      let arrayOfObjects = this.state.filteredata.sort((a:any, b:any) => a.id - b.id);
      let filterData = arrayOfObjects.find((val: any) => val.id === arrayOfObjects[0]?.id);
      this.setState({filteredata:arrayOfObjects})
      this.getparticularCategories(arrayOfObjects[0]?.id)
      this.getAllCategoriesSession(filterData?.id)
    }
    
  }

  setWatchSession = (getSessionId: string) => {
    this.setState({getQuestionloding:true})
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.setWatchedSessionAPIEnd}${getSessionId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  

  handleMapDialogOpen = () => {
    this.splitAdress(this.state.filtercarddata.attributes?.address?this.state.filtercarddata.attributes.address:'')
    this.setState({ openMap: true });
  };

  handleMapDialogClose = () => {
    this.setState({ openMap: false });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  responseFromTabbyMethod = async ()=> {
    let merchantUrl = window.location.pathname;

    if(merchantUrl.includes("TabbyPaymentSuccess")) {
      this.setState({responseFromTabbyMessage: "success", isVisibleModal: true});
    } else if (merchantUrl.includes("TabbyPaymentFailure")) {
      this.setState({responseFromTabbyMessage: "failure", isVisibleModal: true})
    } else if (merchantUrl.includes("TabbyPaymentCanceled")) {
      this.setState({responseFromTabbyMessage: "canceled", isVisibleModal: true})
    }
  }

  goToPaymentHistory = () => {
    this.props.navigation.navigate("GetPayments")
}

convertMinutes = (minutesNumber: number) => {
  const hourConvert = Math.floor(minutesNumber / 60);
  const remainingMinutesConvert = minutesNumber % 60 === 0 ? '' : `and ${minutesNumber % 60} mins`;
  return `${hourConvert} hr ${remainingMinutesConvert}`;
}

getCategoriesName = (categoryId: number) => {
  let categoryName = this.state.categoriesData.map((item: any, index: number) => {
    if (Number(item.id) === categoryId) {
      return item.attributes.name;
    }
  });

  return categoryName;
};

getCategoriesNameFashion = (categoryIdFashion: number) => {
  let categoryNameFashion = this.state.categoriesFashion.map((item: any, index: number) => {
    if (Number(item.id) === categoryIdFashion) {
      return item.attributes.name;
    }
  });

  return categoryNameFashion;
};

isOngoingCourse = () => {
  return this.state.filtercarddata.attributes.no_of_lessons_completed + this.state.filtercarddata.attributes.lessons?.data.length === 0 ? false : this.state.filtercarddata.attributes.no_of_lessons_completed === this.state.filtercarddata.attributes.lessons?.data.length;
}

getCoursePurchasedInfo = (identity:string|number) => {
  const header = {
    "Content-Type": configJSON.categoryApiContentType,
    token: this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getPurchasedInfoId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    this.state.userRole !== "guest" ?
    `${configJSON.AllCategoryAPIEndPoint}/${identity}`
    : `${configJSON.AllParticularCategoryAPIEndPointGuest}/${identity}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

splitAdress(inputString:string) {
  const [firstPart, secondPart] = inputString.split(',').map(part => part.trim());
  this.setState({city:firstPart})
  this.setState({street:secondPart})
  console.log([firstPart, secondPart])
  console.log([this.state.city, this.state.street])
}



validateCourseTypename(inputString:string){
  switch(inputString){
    case 'live_session':
    return 'Live Sessions'
    case 'online_courses':
    return 'Online'
    case 'on_campus_workshop':
    return 'On-Campus Workshops'
  default:
    return ''
  }
}


getReviewRating = () => {
  const getParam = this.props.navigation.getParam("id");
  this.setState({isloading:true})
  const header = {
    "Content-Type": configJSON.categoryApiContentType,
    token: this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getReviewRatingCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getReviewRatingAPIEndPoint + getParam
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

getReviewRatingResponse = ( responseJson: any) => {
  if(responseJson.data[0]){
    this.setState({
      rating: responseJson.data[0].attributes.rating,
      writereview: responseJson.data[0].attributes.description,
      isReviewDone: true
    })
  }else{
    this.setState({
      rating: 0,
      writereview: '',
      isReviewDone: false
    })
  }
}
  // Customizable Area End
}
